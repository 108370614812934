import { PrivacySettings, Status } from '../consts/collectionsConsts';

export const defaultAlbum = {
  settings: {
    id: '41f47fd2-c950-4643-99b8-d11f42d8b91d',
    brand: {
      logo: {
        name: '60cca8_f8ab504d90b14a0ba93b226c94c25d39~mv2.png',
        title: 'runSprite-2',
        mediaUrl: 's',
        photoMetadata: {
          width: 1419,
          height: 492,
        },
      },
      name: 'Exposure',
      type: 'NAME',
    },
    cover: {
      id: '5a487d4d-7817-4fa7-81cb-60c4d3a14f34',
      name: '60cca8_c45cf0f400164443a8a22ecc9f2f4942~mv2.png',
      title: 'IMG_4086',
      dataType: 'Photo',
      isSecure: true,
      mediaUrl:
        'https://static.wixstatic.com/media/60cca8_c45cf0f400164443a8a22ecc9f2f4942~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_c45cf0f400164443a8a22ecc9f2f4942~mv2.png',
      imageToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfYzQ1Y2YwZjQwMDE2NDQ0M2E4YTIyZWNjOWYyZjQ5NDJ-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.qB4ChKaaHpDzGiqNZBRnUaScqvXxW7A-qhFRh6OtyZ0',
      mediaOwner: 'Wix',
      orderIndex: 1638272370618,
      createdDate: '2021-11-30T11:39:32.569Z',
      updatedDate: '2021-11-30T11:39:32.569Z',
      photoMetadata: {
        width: 4032,
        height: 3024,
        focalPoint: {
          x: 0.5,
          y: 0.5,
        },
      },
    },
    colors: {
      pageTexts: '#121212',
      coverTexts: '#121212',
      galleriesTexts: '#121212',
      pageBackground: '#FFFFFF',
      coverBackground: '#FFFFFF',
      galleriesOverlay: '#FFFFFF',
    },
    folder: {
      id: 'f4029c8dc9f64440b6cbc76a044b7fbd',
      name: 'Template V6',
    },
    layout: 'collage',
    siteId: '407c1d43-90ea-4232-851d-92c529aeb49e',
    userId: '60cca876-a865-457d-b852-96265a773c22',
    version: 2,
    welcome: 'View Photos',
    allowSEO: true,
    siteName: 'template-v6',
    template: {
      name: 'modern6',
      title: 'Albums_Wizard_StylePage_Modern',
      colors: {
        pageTexts: '#121212',
        coverTexts: '#121212',
        galleriesTexts: '#121212',
        pageBackground: '#FFFFFF',
        coverBackground: '#FFFFFF',
        galleriesOverlay: '#FFFFFF',
      },
      siteId: 'ac7af6e1-0b76-41a6-802a-968b561a99f6',
      version: 6,
      metasiteId: '2431e9d0-769c-466d-83db-af4f62a695b2',
      useArtStore: true,
      coverPageName: 'modern',
      selectedFontSet: 1,
      coverIsBackground: true,
      transparentImageUrl: '',
      reverseCoverTitleAndDescription: false,
    },
    albumDate: 'November 30, 2021',
    albumName: 'Template V6',
    albumPass: '',
    homeSetId: '1b0ed63d-1b5e-4774-b6fc-93ff06074cb4',
    publicUrl: 'https://dorr42.wixsite.com/template-v6',
    watermark: {
      size: 10,
      opacity: 1,
      imageUrl: '8bb438_39a78b46d4fe47069da63da9348b4ee5~mv2.png',
      position: 1,
      imageRatio: 1,
    },
    accessType: 'PUBLIC',
    metaSiteId: 'ec2b0381-e45e-4a89-acc4-49b3c4fc2219',
    modifiedAt: 1639387721458,
    dateCreated: 1638272368411,
    displayDate: true,
    pricelistId: 'da5cb7c0-59cf-45c9-90c0-4862e2d51622',
    setsSummary: {},
    styleParams: {
      usm_a: 0,
      usm_r: 0,
      usm_t: 0,
      isGrid: false,
      oneRow: false,
      cubeType: 'fill',
      isSlider: false,
      viewMode: 'preview',
      boxShadow: 0,
      cubeRatio: 1.3333333333333333,
      gridStyle: 1,
      groupSize: 3,
      isColumns: false,
      isMasonry: false,
      itemClick: 'expand',
      smartCrop: false,
      usmToggle: false,
      videoLoop: true,
      videoPlay: 'hover',
      allowHover: true,
      allowTitle: false,
      coverTexts: {
        value: '#9A4747',
      },
      cubeImages: false,
      fullscreen: true,
      groupTypes: '1,2h,2v,3t,3b,3l,3r',
      isVertical: false,
      loveButton: true,
      showArrows: false,
      videoSound: false,
      videoSpeed: '1',
      allowSocial: false,
      arrowsColor: {
        value: '#FFFFFF',
      },
      gallerySize: 70,
      galleryType: 'Columns',
      imageMargin: 30,
      imageResize: 0,
      isSlideshow: false,
      itemOpacity: {
        value: 'rgba(41, 41, 41, 0.3)',
      },
      loveCounter: false,
      minItemSize: 50,
      sharpParams: {
        usm: {},
        quality: 90,
      },
      borderRadius: 0,
      cropOnlyFill: false,
      enableScroll: true,
      fixedColumns: 0,
      imageQuality: 90,
      overlayColor: {
        value: '#000000',
      },
      previewHover: false,
      useWatermark: true,
      allowDownload: true,
      galleryLayout: 0,
      hasThumbnails: false,
      imageInfoType: 'NO_BACKGROUND',
      itemFontColor: {
        value: '#FFFFFF',
      },
      itemIconColor: {
        value: '#FFFFFF',
      },
      slideshowLoop: false,
      textBoxHeight: 200,
      thumbnailSize: 120,
      watermarkDock: {
        top: 'auto',
        left: 'auto',
        right: 0,
        bottom: 0,
        transform: 'translate3d(0,0,0)',
      },
      watermarkSize: 40,
      arrowsPosition: 0,
      collageDensity: 1,
      floatingImages: 0,
      fullscreenLoop: false,
      gotStyleParams: true,
      groupsPerStrip: 0,
      itemShadowBlur: 20,
      itemShadowSize: 10,
      loadMoreAmount: 'all',
      mobilePanorama: false,
      overlayOpacity: 0.5,
      placeGroupsLtr: true,
      textImageSpace: 10,
      titlePlacement: 'SHOW_BELOW',
      allowLinkExpand: false,
      alwaysShowHover: false,
      chooseBestGroup: true,
      coverBackground: {
        value: '#FFFFFF',
      },
      expandAnimation: 'NO_EFFECT',
      isAutoSlideshow: false,
      itemBorderColor: {
        value: '#292929',
      },
      itemBorderWidth: 0,
      magicLayoutSeed: 1,
      scrollAnimation: 'FADE_IN',
      scrollDirection: 0,
      useCustomButton: false,
      allowDescription: false,
      allowTitleExpand: true,
      bottomInfoHeight: 0,
      customButtonText: 'Buy Now',
      galleryTextAlign: 'center',
      imageLoadingMode: 'BLUR',
      itemBorderRadius: 0,
      itemEnableShadow: false,
      overlayAnimation: 'FADE_IN',
      textBoxFillColor: {
        value: '#FFFFFF',
      },
      watermarkOpacity: 40,
      hoveringBehaviour: 'APPEARS',
      imageLoadingColor: {
        value: '#CCCCCC',
      },
      rotatingCubeRatio: '',
      slideshowInfoSize: 200,
      thumbnailSpacings: 4,
      expandInfoPosition: 0,
      galleryAlignExpand: 'left',
      loadMoreButtonText: '',
      rotatingCropRatios: '',
      rotatingGroupTypes: '',
      textBoxBorderColor: {
        value: '#292929',
      },
      textBoxBorderWidth: 0,
      addToCartButtonText: '',
      imageHoverAnimation: 'NO_EFFECT',
      itemShadowDirection: 135,
      textBoxBorderRadius: 0,
      addToCartBorderWidth: 1,
      enableInfiniteScroll: true,
      galleryVerticalAlign: 'center',
      numberOfImagesPerCol: 1,
      numberOfImagesPerRow: 3,
      textsVerticalPadding: 0,
      allowFullscreenExpand: true,
      allowSlideshowCounter: false,
      autoSlideshowInterval: 4,
      customButtonFontColor: {
        value: '#FFFFFF',
      },
      defaultShowInfoExpand: 1,
      selectedGalleryLayout: 'collage',
      allowDescriptionExpand: true,
      galleryHorizontalAlign: 'center',
      itemFontColorSlideshow: {
        value: '#292929',
      },
      itemIconColorSlideshow: {
        value: '#292929',
      },
      oneColorAnimationColor: {
        value: '#FFFFFF',
      },
      cubeRatioRepresentation: '4/3',
      customButtonBorderWidth: 1,
      gallerySliderImageRatio: 1.7777777777777777,
      customButtonBorderRadius: 0,
      galleryImageRatioFromWix: 1,
      itemDescriptionFontColor: {
        value: '#FFFFFF',
      },
      itemShadowOpacityAndColor: {
        value: 'rgba(41, 41, 41, 0.2)',
      },
      loadMoreButtonBorderColor: {
        value: '#292929',
      },
      loadMoreButtonBorderWidth: 1,
      calculateTextBoxHeightMode: 'AUTOMATIC',
      galleryThumbnailsAlignment: 'bottom',
      loadMoreButtonBorderRadius: 0,
      playButtonForAutoSlideShow: false,
      customButtonFontColorForHover: {
        value: '#292929',
      },
      useRefactoredGalleryContainer: false,
      itemDescriptionFontColorSlideshow: {
        value: '#292929',
      },
    },
    allowDisplay: true,
    defaultSetId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
    displayBrand: true,
    isStoreAlbum: false,
    userFontSets: [],
    lastWatermark: {
      size: 69,
      opacity: 100,
      imageUrl: '60cca8_2ea64fbec46149328e1374ee2ea20767~mv2.png',
      position: 5,
      imageRatio: 1,
    },
    loginLanguage: 'en',
    motherSiteUrl: 'https://dorr42.wixsite.com/mysite-20',
    ulrModifiedAt: 1638272432238,
    allowSelections: true,
    selectedFontSet: 1,
    loveButtonAction: 'favoritesLists',
    imageAddDirection: 'GALLERY_END',
    allowAlbumDownload: false,
    defaultNewItemName: 'FILE_NAME',
    isWatermarkEnabled: false,
    downloadImageQuality: 1,
    motherSiteMetaSiteId: '81f8f788-cd49-45b4-8806-b5e646cc72f5',
    isDuplicatedOrChangeTemplate: false,
    shouldShowPhotoRightsNotification: false,
  },
  sets: {
    gallery: [
      {
        id: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
        name: 'Gallery',
        totalItemsCount: 31,
        items: [
          {
            id: '5a487d4d-7817-4fa7-81cb-60c4d3a14f34',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_c45cf0f400164443a8a22ecc9f2f4942~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_c45cf0f400164443a8a22ecc9f2f4942~mv2.png',
            orderIndex: 1638272370618,
            name: 'IMG_4086.heic',
            title: 'IMG_4086',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfYzQ1Y2YwZjQwMDE2NDQ0M2E4YTIyZWNjOWYyZjQ5NDJ-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.qB4ChKaaHpDzGiqNZBRnUaScqvXxW7A-qhFRh6OtyZ0',
          },
          {
            id: '1d1cac26-81a2-44ad-8020-ffa14fe42183',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_c5a97becd3984ee3b9ac5b4355c9714d~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_c5a97becd3984ee3b9ac5b4355c9714d~mv2.png',
            orderIndex: 1638272370719,
            name: 'IMG_4087.heic',
            title: 'IMG_4087',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:32Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfYzVhOTdiZWNkMzk4NGVlM2I5YWM1YjQzNTVjOTcxNGR-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.uukcEhvixJ_lUO_VkNM1foPbTHskeKTVSSFSj4ssQ9A',
          },
          {
            id: '47ec7174-2d61-4eda-a349-474d947ed979',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_93b49db17f4f46e6b8b8ee5e684d16dc~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_93b49db17f4f46e6b8b8ee5e684d16dc~mv2.png',
            orderIndex: 1638272370820,
            name: 'IMG_4088.heic',
            title: 'IMG_4088',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:32Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfOTNiNDlkYjE3ZjRmNDZlNmI4YjhlZTVlNjg0ZDE2ZGN-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.A8pgv4yFNp5Stg1-IDaQYeK2uru1dMe4PTjJL9eo3D0',
          },
          {
            id: '330dbec0-4083-4900-90c6-22f23e7929fa',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_9016ea216827454f87e0ba42ea776c40~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_9016ea216827454f87e0ba42ea776c40~mv2.png',
            orderIndex: 1638272370921,
            name: 'IMG_4089.heic',
            title: 'IMG_4089',
            dataType: 'Photo',
            photoMetadata: {
              width: 3024,
              height: 4032,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:32Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfOTAxNmVhMjE2ODI3NDU0Zjg3ZTBiYTQyZWE3NzZjNDB-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.NenAulvNKX7Mi_byAmp9EP05905LeNFCNBC-AmrQd8A',
          },
          {
            id: 'e7aba6e4-132e-4896-ba96-70a50ec9722d',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_f5b3e0cf26a34064b3aab82399098ecd~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_f5b3e0cf26a34064b3aab82399098ecd~mv2.png',
            orderIndex: 1638272371023,
            name: 'IMG_4090.heic',
            title: 'IMG_4090',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:35Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfZjViM2UwY2YyNmEzNDA2NGIzYWFiODIzOTkwOThlY2R-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.LvHcS5EaUR9z_c3NEBaHsVR4bZyKc2QPBpuxBzP9OOc',
          },
          {
            id: '425e8e24-b794-469a-87c4-5b94cfc30188',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_2d0200f60e8c4d739b3d23501a134b07~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_2d0200f60e8c4d739b3d23501a134b07~mv2.png',
            orderIndex: 1638272371124,
            name: 'IMG_4091.heic',
            title: 'IMG_4091',
            dataType: 'Photo',
            photoMetadata: {
              width: 3024,
              height: 4032,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:34Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfMmQwMjAwZjYwZThjNGQ3MzliM2QyMzUwMWExMzRiMDd-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.BoOUPRKGs3zIGUZ5Mso3lMSNm5U2OiTpJKkFNKKZhOc',
          },
          {
            id: 'a19bb173-3f1b-4c77-a57e-02f48b4793c9',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_5aa267c2a6d245178d21e01cc1d7d3b9~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_5aa267c2a6d245178d21e01cc1d7d3b9~mv2.png',
            orderIndex: 1638272371225,
            name: 'IMG_4092.heic',
            title: 'IMG_4092',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:34Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfNWFhMjY3YzJhNmQyNDUxNzhkMjFlMDFjYzFkN2QzYjl-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.5XR8nUnTOtIFs81EImvy7Umk-g3whCm_g0ftkgVayNQ',
          },
          {
            id: 'c7c55e28-7018-485c-81a7-77aafacd4b74',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_e70b50f85c4f4066a4c894687958db19~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_e70b50f85c4f4066a4c894687958db19~mv2.png',
            orderIndex: 1638272371326,
            name: 'IMG_4093.heic',
            title: 'IMG_4093',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:35Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfZTcwYjUwZjg1YzRmNDA2NmE0Yzg5NDY4Nzk1OGRiMTl-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.EvTYhJFkWydZPX96s1O75JgAaTLrBhoEwAoXM5twvFY',
          },
          {
            id: 'a57c5747-ed8f-4f58-9010-d82c78123e46',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_a7acaa86944645a2bf2516dc11240e8e~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_a7acaa86944645a2bf2516dc11240e8e~mv2.png',
            orderIndex: 1638272371428,
            name: 'IMG_4094.heic',
            title: 'IMG_4094',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:40Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfYTdhY2FhODY5NDQ2NDVhMmJmMjUxNmRjMTEyNDBlOGV-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.DZl1KdCIXg0pfRfQBHJ96J-A4-ImFjrMdnJry0RsFGg',
          },
          {
            id: '06f72e6e-783f-42d0-9505-2e848dd9f46b',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_7e644ad757294ff99d86eba71a8d04ea~mv2.png/v1/fill/w_2316,h_3088,al_c/60cca8_7e644ad757294ff99d86eba71a8d04ea~mv2.png',
            orderIndex: 1638272371529,
            name: 'IMG_4095.heic',
            title: 'IMG_4095',
            dataType: 'Photo',
            photoMetadata: {
              width: 2316,
              height: 3088,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:39Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfN2U2NDRhZDc1NzI5NGZmOTlkODZlYmE3MWE4ZDA0ZWF-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.xzUiWtVBCPO5i9yl6eZXVq7UYOFnfOCP6UcHxrffLCA',
          },
          {
            id: '14c65909-47e8-454a-be0e-9576a103eb20',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_6732a59e2f2b4381ae5273c33fe6bf1b~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_6732a59e2f2b4381ae5273c33fe6bf1b~mv2.png',
            orderIndex: 1638272371630,
            name: 'IMG_4096.heic',
            title: 'IMG_4096',
            dataType: 'Photo',
            photoMetadata: {
              width: 3024,
              height: 4032,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:41Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfNjczMmE1OWUyZjJiNDM4MWFlNTI3M2MzM2ZlNmJmMWJ-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.62CwUKg2xQwpQiUUOx7qoCc9Pi_TsC9Eqk2ZnYhD8Mc',
          },
          {
            id: '89d807d8-9b74-4793-b519-5033416be307',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_502eabb876534373beadc09e75ff1a1b~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_502eabb876534373beadc09e75ff1a1b~mv2.png',
            orderIndex: 1638272371731,
            name: 'IMG_4097.heic',
            title: 'IMG_4097',
            dataType: 'Photo',
            photoMetadata: {
              width: 3024,
              height: 4032,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:42Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfNTAyZWFiYjg3NjUzNDM3M2JlYWRjMDllNzVmZjFhMWJ-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.mR8gJiGpBCmXdlb-VwDG-OUKKYL9HV-N842KU4L_k4Y',
          },
          {
            id: '7526614c-f690-4667-9afa-d85385e5f8b0',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_295d75986d2045c4b7bdf1f4c86b4ebc~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_295d75986d2045c4b7bdf1f4c86b4ebc~mv2.png',
            orderIndex: 1638272371833,
            name: 'IMG_4098.heic',
            title: 'IMG_4098',
            dataType: 'Photo',
            photoMetadata: {
              width: 3024,
              height: 4032,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:47Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfMjk1ZDc1OTg2ZDIwNDVjNGI3YmRmMWY0Yzg2YjRlYmN-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.3_r4EYDb58tfbJ8igEqhS3fIfQc4psfjzKgM4G0zX70',
          },
          {
            id: '82fde4d2-9171-4226-bc32-39a17da6874a',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_96a80274c2b44c44944a39062019067d~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_96a80274c2b44c44944a39062019067d~mv2.png',
            orderIndex: 1638272371934,
            name: 'IMG_4099.heic',
            title: 'IMG_4099',
            dataType: 'Photo',
            photoMetadata: {
              width: 3024,
              height: 4032,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:49Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfOTZhODAyNzRjMmI0NGM0NDk0NGEzOTA2MjAxOTA2N2R-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.jKfVewhZNOUfbnWa0A_7oRczrLkM1RsuJr61B_MSAU8',
          },
          {
            id: '1c1a7a58-56ae-4012-ad87-2a81044475dd',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_a61f02bebd464564ac2332cbf9c0dc95~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_a61f02bebd464564ac2332cbf9c0dc95~mv2.png',
            orderIndex: 1638272372035,
            name: 'IMG_4100.heic',
            title: 'IMG_4100',
            dataType: 'Photo',
            photoMetadata: {
              width: 3024,
              height: 4032,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:49Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfYTYxZjAyYmViZDQ2NDU2NGFjMjMzMmNiZjljMGRjOTV-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.38-SdmSJJ98UNmuGnA3gkKcnO1pz6qVEXRWDZ94ISaQ',
          },
          {
            id: '76042bf1-e076-4545-8c7a-d77193dca1a7',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_e85a5a3cf44746b2b35b12f4e3368a92~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_e85a5a3cf44746b2b35b12f4e3368a92~mv2.png',
            orderIndex: 1638272372136,
            name: 'IMG_4101.heic',
            title: 'IMG_4101',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:52Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfZTg1YTVhM2NmNDQ3NDZiMmIzNWIxMmY0ZTMzNjhhOTJ-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.hXIVHgzRSASabLsP_p2NczItLJero1q2iBA_mLdx0nk',
          },
          {
            id: 'ddd97a5a-c6d1-4aac-8e8d-97119ee77129',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_51c4e9c52b9c4205a5def08d9292418a~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_51c4e9c52b9c4205a5def08d9292418a~mv2.png',
            orderIndex: 1638272372237,
            name: 'IMG_4102.heic',
            title: 'IMG_4102',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:56Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfNTFjNGU5YzUyYjljNDIwNWE1ZGVmMDhkOTI5MjQxOGF-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.EXR_OR4yQkiokBcWX8tPQxycaDQWPCe73tcD1riggt4',
          },
          {
            id: '25fe079b-2d71-437e-9c27-6288697e4b6b',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_ad7ced4a4d7248bcac10c12775e373a4~mv2.png/v1/fill/w_2316,h_3088,al_c/60cca8_ad7ced4a4d7248bcac10c12775e373a4~mv2.png',
            orderIndex: 1638272372338,
            name: 'IMG_4103.heic',
            title: 'IMG_4103',
            dataType: 'Photo',
            photoMetadata: {
              width: 2316,
              height: 3088,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:53Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfYWQ3Y2VkNGE0ZDcyNDhiY2FjMTBjMTI3NzVlMzczYTR-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.wTzBR3aU5cNi3iO4aRNG_GoEVBi4lZrnmGs5X8I14y0',
          },
          {
            id: 'bd016e7c-1989-43d8-9799-bf8fb6b035f9',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_878a4af87c98450abd489976661c351b~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_878a4af87c98450abd489976661c351b~mv2.png',
            orderIndex: 1638272372440,
            name: 'IMG_4104.heic',
            title: 'IMG_4104',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:56Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfODc4YTRhZjg3Yzk4NDUwYWJkNDg5OTc2NjYxYzM1MWJ-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.qYFOJDl4M57PlJ0FmB1WHAjXzA_LbCN2LNkh2A0yusU',
          },
          {
            id: '5ee5ac79-1850-4684-8aa3-bba12256ede0',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_57eeac8e110c472683d673842257cc61~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_57eeac8e110c472683d673842257cc61~mv2.png',
            orderIndex: 1638272372541,
            name: 'IMG_4105.heic',
            title: 'IMG_4105',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:39:58Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfNTdlZWFjOGUxMTBjNDcyNjgzZDY3Mzg0MjI1N2NjNjF-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.iyVhAfx6NsJRYydFJI3PLqPrXpGpTui4fJu5Nj7CB6A',
          },
          {
            id: 'e7d95670-cd7f-4a54-bc26-7f4e0c53567a',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_1187d3cf7896451e8db78a6014d7e140~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_1187d3cf7896451e8db78a6014d7e140~mv2.png',
            orderIndex: 1638272372642,
            name: 'IMG_4106.heic',
            title: 'IMG_4106',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:00Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfMTE4N2QzY2Y3ODk2NDUxZThkYjc4YTYwMTRkN2UxNDB-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.ZogfDZTjbb1hY6EbduMHCaLNWanAJZBLwgwndSGXhkE',
          },
          {
            id: 'b0be1327-4de1-48f3-a349-0d7651ad01ac',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_8e6ca67d54594d8586ad0c1e976c915d~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_8e6ca67d54594d8586ad0c1e976c915d~mv2.png',
            orderIndex: 1638272372743,
            name: 'IMG_4107.heic',
            title: 'IMG_4107',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:01Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfOGU2Y2E2N2Q1NDU5NGQ4NTg2YWQwYzFlOTc2YzkxNWR-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.yJwHvEAoLrYoLHvAUz7BGXDR5kee2UR118cXHRxpZns',
          },
          {
            id: 'e3410b18-01de-46dd-aedf-e5b1b3c555db',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_3956b2df2c304fecb9a966179e6c204e~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_3956b2df2c304fecb9a966179e6c204e~mv2.png',
            orderIndex: 1638272372844,
            name: 'IMG_4108.heic',
            title: 'IMG_4108',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:04Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfMzk1NmIyZGYyYzMwNGZlY2I5YTk2NjE3OWU2YzIwNGV-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.Iexcp1xE8c-poQ32nDrASTqk363vjKHlwV1nVJBz6zM',
          },
          {
            id: 'b2ad01fe-b939-4b72-bcc3-927d5718172b',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_8881146654574d4fa6433e2181717639~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_8881146654574d4fa6433e2181717639~mv2.png',
            orderIndex: 1638272372945,
            name: 'IMG_4109.heic',
            title: 'IMG_4109',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:05Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfODg4MTE0NjY1NDU3NGQ0ZmE2NDMzZTIxODE3MTc2Mzl-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.LQgnpifeizT925lS0RLDE_DUC0lmCAaE7akTzr6CkCc',
          },
          {
            id: '4d8d9916-6ba9-4474-b343-899d28dc1f80',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_62e29622bb4d4e71a516bfe069dbbbbf~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_62e29622bb4d4e71a516bfe069dbbbbf~mv2.png',
            orderIndex: 1638272373046,
            name: 'IMG_4110.heic',
            title: 'IMG_4110',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:05Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfNjJlMjk2MjJiYjRkNGU3MWE1MTZiZmUwNjlkYmJiYmZ-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.sW8K8-rirlKLb3LzKdkWqMFnUsY4kqwff44WrI1Bn3w',
          },
          {
            id: 'e44c2b8e-c6c7-45aa-acba-3166b82ab931',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_8defdaa3c46a40d0ada1ba8fe5da33c4~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_8defdaa3c46a40d0ada1ba8fe5da33c4~mv2.png',
            orderIndex: 1638272373148,
            name: 'IMG_4111.heic',
            title: 'IMG_4111',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:07Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfOGRlZmRhYTNjNDZhNDBkMGFkYTFiYThmZTVkYTMzYzR-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.AzxPSlDEofJ_I-H2VBE8j7O9_FR4t_QFnQmwQmO_vFw',
          },
          {
            id: '995169e3-eac7-4a23-8225-cba9d7509197',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_97e4438b0a514cd79d40684052f31b34~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_97e4438b0a514cd79d40684052f31b34~mv2.png',
            orderIndex: 1638272373249,
            name: 'IMG_4112.heic',
            title: 'IMG_4112',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:10Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfOTdlNDQzOGIwYTUxNGNkNzlkNDA2ODQwNTJmMzFiMzR-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.h8W43nnxbmmtKlf9HdH4-UdFzHwrKMzFSPkVdE4pcOI',
          },
          {
            id: 'e21b65bc-7161-4017-a29e-44d5d228bbc9',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_6017f89d96344b11a59848383b173691~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_6017f89d96344b11a59848383b173691~mv2.png',
            orderIndex: 1638272373350,
            name: 'IMG_4113.heic',
            title: 'IMG_4113',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:12Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfNjAxN2Y4OWQ5NjM0NGIxMWE1OTg0ODM4M2IxNzM2OTF-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.sT20-VKKiuSZaShY0WQbAyWmOhYkvBqVEbd3ecM2qAM',
          },
          {
            id: 'e1cb2a6f-5ce2-46a7-9d22-f73e966930c9',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_d0533089af804fe6a5efd04d7e4e9022~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_d0533089af804fe6a5efd04d7e4e9022~mv2.png',
            orderIndex: 1638272373451,
            name: 'IMG_4114.heic',
            title: 'IMG_4114',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:13Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfZDA1MzMwODlhZjgwNGZlNmE1ZWZkMDRkN2U0ZTkwMjJ-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.GKgIRbjNZwiaoI7JJfBe3axTW8neLIjiO74X9tnHnlk',
          },
          {
            id: '2077c3a5-7cf7-4583-9e3e-f4fe6da7cb4b',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_e3e76c9bd4334a8c9067c10339869b04~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_e3e76c9bd4334a8c9067c10339869b04~mv2.png',
            orderIndex: 1638272373552,
            name: 'IMG_4115.heic',
            title: 'IMG_4115',
            dataType: 'Photo',
            photoMetadata: {
              width: 4032,
              height: 3024,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:14Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfZTNlNzZjOWJkNDMzNGE4YzkwNjdjMTAzMzk4NjliMDR-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.Ipha80UqIFqnx6fUulE6qrXBwG_j68Z0BvZwei0skqI',
          },
          {
            id: '74d42866-ce7c-4dcf-9816-114e5f5658ea',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_7deda33f689642159801ab128b8c506f~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_7deda33f689642159801ab128b8c506f~mv2.png',
            orderIndex: 1638272373653,
            name: 'IMG_4116.heic',
            title: 'IMG_4116',
            dataType: 'Photo',
            photoMetadata: {
              width: 3024,
              height: 4032,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            createdDate: '2021-11-30T11:40:18Z',
            mediaOwner: 'Wix',
            imageToken:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfN2RlZGEzM2Y2ODk2NDIxNTk4MDFhYjEyOGI4YzUwNmZ-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.euSYlL4gBi8gYuVZ8IfFTC84g4HqxSxRaX-A02JcGZY',
          },
        ],
        created: '2021-11-30T11:40:32Z',
        createdDate: '2021-11-30T11:40:32Z',
        orderIndex: 1638272369317,
      },
    ],
    totalGalleriesCount: 1,
  },
} as AlbumData;

export const defaultList = {
  mediaCollection: {
    id: '67e1f1c6-0293-4237-b156-2f53c950d526',
    name: 'For Retouching',
    description: 'Yesss',
    membersCount: 1,
    creator: {
      memberId: '60cca876-a865-457d-b852-96265a773c22',
      nickname: 'Dor Raba',
      slug: 'dorr',
      photoUrl:
        'https://lh3.googleusercontent.com/a-/AOh14Gi7NHrvmJ_mL4aa93X1ebGOlkasFaWrNzzEsPSp%3Ds96-c',
    },
    createdDate: '2021-11-30T11:42:56.708Z',
    items: [
      {
        id: '06f72e6e-783f-42d0-9505-2e848dd9f46b',
        mediaUrl:
          'https://static.wixstatic.com/media/60cca8_7e644ad757294ff99d86eba71a8d04ea~mv2.png/v1/fill/w_2316,h_3088,al_c/60cca8_7e644ad757294ff99d86eba71a8d04ea~mv2.png',
        sortOrder: 1638272634938,
        name: 'IMG_4095.heic',
        title: 'IMG_4095',
        dataType: 'Photo',
        photoMetadata: {
          width: 2316,
          height: 3088,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        secure: false,
        addedDate: '2021-11-30T11:43:55Z',
        updatedDate: '2021-11-30T11:43:55Z',
        mediaOwner: 'Wix',
        tags: [],
        token:
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXSwid21rIjp7InBhdGgiOiIvbWVkaWEvOGJiNDM4XzM5YTc4YjQ2ZDRmZTQ3MDY5ZGE2M2RhOTM0OGI0ZWU1fm12Mi5wbmciLCJncmF2aXR5Ijoibm9ydGgtd2VzdCIsInByb3BvcnRpb25zIjowLjEsIm9wYWNpdHkiOjF9LCJvYmoiOltbeyJwYXRoIjoiL21lZGlhLzYwY2NhOF83ZTY0NGFkNzU3Mjk0ZmY5OWQ4NmViYTcxYThkMDRlYX5tdjIucG5nIn1dXSwiaXNzIjoidXJuOmFwcDo2YmU0ZjRhZjIwMzk0MDk1YWQ2OWNjYzM5ODg5MmM4ZCIsImV4cCI6MTY0MDk5NTIwMCwianRpIjoiMTY0MDk5NTIwMCJ9.TfKA6iNIU8kDFpXA3ISnxuaMf4R_NiwkX-r-5dAi8n0',
        galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
      },
      {
        id: '14c65909-47e8-454a-be0e-9576a103eb20',
        mediaUrl:
          'https://static.wixstatic.com/media/60cca8_6732a59e2f2b4381ae5273c33fe6bf1b~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_6732a59e2f2b4381ae5273c33fe6bf1b~mv2.png',
        sortOrder: 1638272634939,
        name: 'IMG_4096.heic',
        title: 'IMG_4096',
        dataType: 'Photo',
        photoMetadata: {
          width: 3024,
          height: 4032,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        secure: false,
        addedDate: '2021-11-30T11:43:55Z',
        updatedDate: '2021-11-30T11:43:55Z',
        mediaOwner: 'Wix',
        tags: [],
        token:
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXSwid21rIjp7InBhdGgiOiIvbWVkaWEvOGJiNDM4XzM5YTc4YjQ2ZDRmZTQ3MDY5ZGE2M2RhOTM0OGI0ZWU1fm12Mi5wbmciLCJncmF2aXR5Ijoibm9ydGgtd2VzdCIsInByb3BvcnRpb25zIjowLjEsIm9wYWNpdHkiOjF9LCJvYmoiOltbeyJwYXRoIjoiL21lZGlhLzYwY2NhOF82NzMyYTU5ZTJmMmI0MzgxYWU1MjczYzMzZmU2YmYxYn5tdjIucG5nIn1dXSwiaXNzIjoidXJuOmFwcDo2YmU0ZjRhZjIwMzk0MDk1YWQ2OWNjYzM5ODg5MmM4ZCIsImV4cCI6MTY0MDk5NTIwMCwianRpIjoiMTY0MDk5NTIwMCJ9.yB0LrDxXMEBdGJk4-yLkGm7U8zs0hh6G8d4qQVGWobg',
        galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
      },
      {
        id: '25fe079b-2d71-437e-9c27-6288697e4b6b',
        mediaUrl:
          'https://static.wixstatic.com/media/60cca8_ad7ced4a4d7248bcac10c12775e373a4~mv2.png/v1/fill/w_2316,h_3088,al_c/60cca8_ad7ced4a4d7248bcac10c12775e373a4~mv2.png',
        sortOrder: 1638272634941,
        name: 'IMG_4103.heic',
        title: 'IMG_4103',
        dataType: 'Photo',
        photoMetadata: {
          width: 2316,
          height: 3088,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        secure: false,
        addedDate: '2021-11-30T11:43:55Z',
        updatedDate: '2021-11-30T11:43:55Z',
        mediaOwner: 'Wix',
        tags: [],
        token:
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXSwid21rIjp7InBhdGgiOiIvbWVkaWEvOGJiNDM4XzM5YTc4YjQ2ZDRmZTQ3MDY5ZGE2M2RhOTM0OGI0ZWU1fm12Mi5wbmciLCJncmF2aXR5Ijoibm9ydGgtd2VzdCIsInByb3BvcnRpb25zIjowLjEsIm9wYWNpdHkiOjF9LCJvYmoiOltbeyJwYXRoIjoiL21lZGlhLzYwY2NhOF9hZDdjZWQ0YTRkNzI0OGJjYWMxMGMxMjc3NWUzNzNhNH5tdjIucG5nIn1dXSwiaXNzIjoidXJuOmFwcDo2YmU0ZjRhZjIwMzk0MDk1YWQ2OWNjYzM5ODg5MmM4ZCIsImV4cCI6MTY0MDk5NTIwMCwianRpIjoiMTY0MDk5NTIwMCJ9.1lQaPqRXEz8H_4gSwCO-fbxMm0AmZU8XOMJblKELvsM',
        galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
      },
      {
        id: '330dbec0-4083-4900-90c6-22f23e7929fa',
        mediaUrl:
          'https://static.wixstatic.com/media/60cca8_9016ea216827454f87e0ba42ea776c40~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_9016ea216827454f87e0ba42ea776c40~mv2.png',
        sortOrder: 1638272634935,
        name: 'IMG_4089.heic',
        title: 'IMG_4089',
        dataType: 'Photo',
        photoMetadata: {
          width: 3024,
          height: 4032,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        secure: false,
        addedDate: '2021-11-30T11:43:55Z',
        updatedDate: '2021-11-30T11:43:55Z',
        mediaOwner: 'Wix',
        tags: [],
        token:
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXSwid21rIjp7InBhdGgiOiIvbWVkaWEvOGJiNDM4XzM5YTc4YjQ2ZDRmZTQ3MDY5ZGE2M2RhOTM0OGI0ZWU1fm12Mi5wbmciLCJncmF2aXR5Ijoibm9ydGgtd2VzdCIsInByb3BvcnRpb25zIjowLjEsIm9wYWNpdHkiOjF9LCJvYmoiOltbeyJwYXRoIjoiL21lZGlhLzYwY2NhOF85MDE2ZWEyMTY4Mjc0NTRmODdlMGJhNDJlYTc3NmM0MH5tdjIucG5nIn1dXSwiaXNzIjoidXJuOmFwcDo2YmU0ZjRhZjIwMzk0MDk1YWQ2OWNjYzM5ODg5MmM4ZCIsImV4cCI6MTY0MDk5NTIwMCwianRpIjoiMTY0MDk5NTIwMCJ9.oTzl5BmlAzlQ2vN0mTy_1rs-pCJo79sxLm_kCxxvAek',
        galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
      },
      {
        id: '425e8e24-b794-469a-87c4-5b94cfc30188',
        mediaUrl:
          'https://static.wixstatic.com/media/60cca8_2d0200f60e8c4d739b3d23501a134b07~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_2d0200f60e8c4d739b3d23501a134b07~mv2.png',
        sortOrder: 1638272634936,
        name: 'IMG_4091.heic',
        title: 'IMG_4091',
        dataType: 'Photo',
        photoMetadata: {
          width: 3024,
          height: 4032,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        secure: false,
        addedDate: '2021-11-30T11:43:55Z',
        updatedDate: '2021-11-30T11:43:55Z',
        mediaOwner: 'Wix',
        tags: [],
        token:
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXSwid21rIjp7InBhdGgiOiIvbWVkaWEvOGJiNDM4XzM5YTc4YjQ2ZDRmZTQ3MDY5ZGE2M2RhOTM0OGI0ZWU1fm12Mi5wbmciLCJncmF2aXR5Ijoibm9ydGgtd2VzdCIsInByb3BvcnRpb25zIjowLjEsIm9wYWNpdHkiOjF9LCJvYmoiOltbeyJwYXRoIjoiL21lZGlhLzYwY2NhOF8yZDAyMDBmNjBlOGM0ZDczOWIzZDIzNTAxYTEzNGIwN35tdjIucG5nIn1dXSwiaXNzIjoidXJuOmFwcDo2YmU0ZjRhZjIwMzk0MDk1YWQ2OWNjYzM5ODg5MmM4ZCIsImV4cCI6MTY0MDk5NTIwMCwianRpIjoiMTY0MDk5NTIwMCJ9.gevuXG6OCvK8MVqhx0rwoTRhynEc5SQnbUKBzrz4-D0',
        galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
      },
      {
        id: '89d807d8-9b74-4793-b519-5033416be307',
        mediaUrl:
          'https://static.wixstatic.com/media/60cca8_502eabb876534373beadc09e75ff1a1b~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_502eabb876534373beadc09e75ff1a1b~mv2.png',
        sortOrder: 1638272634940,
        name: 'IMG_4097.heic',
        title: 'IMG_4097',
        dataType: 'Photo',
        photoMetadata: {
          width: 3024,
          height: 4032,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        secure: false,
        addedDate: '2021-11-30T11:43:55Z',
        updatedDate: '2021-11-30T11:43:55Z',
        mediaOwner: 'Wix',
        tags: [],
        token:
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXSwid21rIjp7InBhdGgiOiIvbWVkaWEvOGJiNDM4XzM5YTc4YjQ2ZDRmZTQ3MDY5ZGE2M2RhOTM0OGI0ZWU1fm12Mi5wbmciLCJncmF2aXR5Ijoibm9ydGgtd2VzdCIsInByb3BvcnRpb25zIjowLjEsIm9wYWNpdHkiOjF9LCJvYmoiOltbeyJwYXRoIjoiL21lZGlhLzYwY2NhOF81MDJlYWJiODc2NTM0MzczYmVhZGMwOWU3NWZmMWExYn5tdjIucG5nIn1dXSwiaXNzIjoidXJuOmFwcDo2YmU0ZjRhZjIwMzk0MDk1YWQ2OWNjYzM5ODg5MmM4ZCIsImV4cCI6MTY0MDk5NTIwMCwianRpIjoiMTY0MDk5NTIwMCJ9.SPrnwyQM_RVt_2N8MhTnHZK6FOjbVUmNa8MzREMtMiI',
        galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
      },
      {
        id: 'bd016e7c-1989-43d8-9799-bf8fb6b035f9',
        mediaUrl:
          'https://static.wixstatic.com/media/60cca8_878a4af87c98450abd489976661c351b~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_878a4af87c98450abd489976661c351b~mv2.png',
        sortOrder: 1638272634942,
        name: 'IMG_4104.heic',
        title: 'IMG_4104',
        dataType: 'Photo',
        photoMetadata: {
          width: 4032,
          height: 3024,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        secure: false,
        addedDate: '2021-11-30T11:43:55Z',
        updatedDate: '2021-11-30T11:43:55Z',
        mediaOwner: 'Wix',
        tags: [],
        token:
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXSwid21rIjp7InBhdGgiOiIvbWVkaWEvOGJiNDM4XzM5YTc4YjQ2ZDRmZTQ3MDY5ZGE2M2RhOTM0OGI0ZWU1fm12Mi5wbmciLCJncmF2aXR5Ijoibm9ydGgtd2VzdCIsInByb3BvcnRpb25zIjowLjEsIm9wYWNpdHkiOjF9LCJvYmoiOltbeyJwYXRoIjoiL21lZGlhLzYwY2NhOF84NzhhNGFmODdjOTg0NTBhYmQ0ODk5NzY2NjFjMzUxYn5tdjIucG5nIn1dXSwiaXNzIjoidXJuOmFwcDo2YmU0ZjRhZjIwMzk0MDk1YWQ2OWNjYzM5ODg5MmM4ZCIsImV4cCI6MTY0MDk5NTIwMCwianRpIjoiMTY0MDk5NTIwMCJ9.GgSnVBsTIyeETltNGiPrQf8qOwyEGliTGv6iAd7_mhU',
        galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
      },
      {
        id: 'c7c55e28-7018-485c-81a7-77aafacd4b74',
        mediaUrl:
          'https://static.wixstatic.com/media/60cca8_e70b50f85c4f4066a4c894687958db19~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_e70b50f85c4f4066a4c894687958db19~mv2.png',
        sortOrder: 1638272634937,
        name: 'IMG_4093.heic',
        title: 'IMG_4093',
        dataType: 'Photo',
        photoMetadata: {
          width: 4032,
          height: 3024,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        secure: false,
        addedDate: '2021-11-30T11:43:55Z',
        updatedDate: '2021-11-30T11:43:55Z',
        mediaOwner: 'Wix',
        tags: [],
        token:
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXSwid21rIjp7InBhdGgiOiIvbWVkaWEvOGJiNDM4XzM5YTc4YjQ2ZDRmZTQ3MDY5ZGE2M2RhOTM0OGI0ZWU1fm12Mi5wbmciLCJncmF2aXR5Ijoibm9ydGgtd2VzdCIsInByb3BvcnRpb25zIjowLjEsIm9wYWNpdHkiOjF9LCJvYmoiOltbeyJwYXRoIjoiL21lZGlhLzYwY2NhOF9lNzBiNTBmODVjNGY0MDY2YTRjODk0Njg3OTU4ZGIxOX5tdjIucG5nIn1dXSwiaXNzIjoidXJuOmFwcDo2YmU0ZjRhZjIwMzk0MDk1YWQ2OWNjYzM5ODg5MmM4ZCIsImV4cCI6MTY0MDk5NTIwMCwianRpIjoiMTY0MDk5NTIwMCJ9.WGnja1oua7fGJgLtNlTSrllsU2JiFiFsCzvwiSSMHp8',
        galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
      },
      {
        id: 'e7d95670-cd7f-4a54-bc26-7f4e0c53567a',
        mediaUrl:
          'https://static.wixstatic.com/media/60cca8_1187d3cf7896451e8db78a6014d7e140~mv2.png/v1/fill/w_4032,h_3024,al_c/60cca8_1187d3cf7896451e8db78a6014d7e140~mv2.png',
        sortOrder: 1638272634943,
        name: 'IMG_4106.heic',
        title: 'IMG_4106',
        dataType: 'Photo',
        photoMetadata: {
          width: 4032,
          height: 3024,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        secure: false,
        addedDate: '2021-11-30T11:43:55Z',
        updatedDate: '2021-11-30T11:43:55Z',
        mediaOwner: 'Wix',
        tags: [],
        token:
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXSwid21rIjp7InBhdGgiOiIvbWVkaWEvOGJiNDM4XzM5YTc4YjQ2ZDRmZTQ3MDY5ZGE2M2RhOTM0OGI0ZWU1fm12Mi5wbmciLCJncmF2aXR5Ijoibm9ydGgtd2VzdCIsInByb3BvcnRpb25zIjowLjEsIm9wYWNpdHkiOjF9LCJvYmoiOltbeyJwYXRoIjoiL21lZGlhLzYwY2NhOF8xMTg3ZDNjZjc4OTY0NTFlOGRiNzhhNjAxNGQ3ZTE0MH5tdjIucG5nIn1dXSwiaXNzIjoidXJuOmFwcDo2YmU0ZjRhZjIwMzk0MDk1YWQ2OWNjYzM5ODg5MmM4ZCIsImV4cCI6MTY0MDk5NTIwMCwianRpIjoiMTY0MDk5NTIwMCJ9.dubpQDGy3Idz1-fbS0BjzS8wyiFSJUeAKMJ1lKmtqQ4',
        galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
      },
    ],
    itemsCount: 9,
    privacySettings: PrivacySettings.Public,
    locked: false,
  },
  memberStatus: Status.UNDEFINED,
};

export const defaultLists = {
  collectionsStatus: [
    {
      mediaCollection: {
        id: '67e1f1c6-0293-4237-b156-2f53c950d526',
        name: 'For Retouching',
        description: 'Yesss',
        membersCount: 1,
        creator: {
          memberId: '60cca876-a865-457d-b852-96265a773c22',
          nickname: 'Dor Raba',
          slug: 'dorr',
          photoUrl:
            'https://lh3.googleusercontent.com/a-/AOh14Gi7NHrvmJ_mL4aa93X1ebGOlkasFaWrNzzEsPSp%3Ds96-c',
        },
        createdDate: '2021-11-30T11:42:56.708Z',
        items: [
          {
            id: '330dbec0-4083-4900-90c6-22f23e7929fa',
            mediaUrl:
              'https://static.wixstatic.com/media/60cca8_9016ea216827454f87e0ba42ea776c40~mv2.png/v1/fill/w_3024,h_4032,al_c/60cca8_9016ea216827454f87e0ba42ea776c40~mv2.png',
            sortOrder: 1638272634935,
            name: 'IMG_4089.heic',
            title: 'IMG_4089',
            dataType: 'Photo',
            photoMetadata: {
              width: 3024,
              height: 4032,
              focalPoint: {
                x: 0.5,
                y: 0.5,
              },
            },
            secure: false,
            addedDate: '2021-11-30T11:43:55Z',
            updatedDate: '2021-11-30T11:43:55Z',
            mediaOwner: 'Wix',
            tags: [],
            token:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46YXBwOjZiZTRmNGFmMjAzOTQwOTVhZDY5Y2NjMzk4ODkyYzhkIiwib2JqIjpbW3sicGF0aCI6Ii9tZWRpYS82MGNjYThfOTAxNmVhMjE2ODI3NDU0Zjg3ZTBiYTQyZWE3NzZjNDB-bXYyLnBuZyJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl0sImlzcyI6InVybjphcHA6NmJlNGY0YWYyMDM5NDA5NWFkNjljY2MzOTg4OTJjOGQiLCJpYXQiOjEwMDAsImp0aSI6IjE2NTY2MzM2MDAiLCJleHAiOjE2NTY2MzM2MDAsIndtayI6eyJwYXRoIjoiL21lZGlhLzhiYjQzOF8zOWE3OGI0NmQ0ZmU0NzA2OWRhNjNkYTkzNDhiNGVlNX5tdjIucG5nIiwib3BhY2l0eSI6MSwicHJvcG9ydGlvbnMiOjAuMSwiZ3Jhdml0eSI6Im5vcnRoLXdlc3QifX0.NenAulvNKX7Mi_byAmp9EP05905LeNFCNBC-AmrQd8A',
            galleryId: '6fd76b3e-a951-48c1-9734-fc2183fff14f',
          },
        ],
        itemsCount: 9,
        privacySettings: PrivacySettings.Public,
        locked: false,
      },
      memberStatus: Status.UNDEFINED,
    },
  ],
  totalCollectionsCount: 1,
};
