import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import collectionsApi from '../../api/collections-api';
import { createNavigator } from '../../services/navigation';
import { Status } from '../../consts/collectionsConsts';
import { getBaseUrl, translateWithParams } from '../../common/utils';
import { isNotListMember } from '../../common/helpers/permissions';
import { collectionCreated, popupOpened } from '@wix/bi-logger-photo-ugc/v2';
import type DataFetcher from '../../viewerScriptHelpers/DataFetcher';
import { defaultLists } from '../../viewerScriptHelpers/defaultData';
import type AppStateManager from '../../viewerScriptHelpers/AppStateManager';

let instance = '';

const createController: CreateControllerFn = async (props) => {
  const { flowAPI, controllerConfig, appData } = props;
  const { dataFetcher, appStateManager } = appData as {
    dataFetcher: DataFetcher;
    appStateManager: AppStateManager;
  };
  const { setProps, appParams, platformAPIs, $w } = flowAPI.controllerConfig;
  const { type, wixCodeApi } = controllerConfig;
  const {
    baseUrls = {},
    appDefinitionId,
    instance: initialInstance,
    instanceId,
  } = appParams;
  const {
    translations: { t },
    environment,
    bi,
  } = flowAPI;
  const isViewer = environment.isViewer || environment.isSSR;

  const {
    getCollections,
    joinCollection,
    leaveCollection,
    createCollectionOnServer,
  } = collectionsApi(flowAPI.httpClient);

  instance = initialInstance;
  let mediaCollections: CollectionAndStatus[] = [];
  const baseUrl = getBaseUrl(isViewer);

  const albumData = await dataFetcher.getAlbumData({
    baseUrl,
  });

  mediaCollections = environment.isEditor
    ? defaultLists.collectionsStatus
    : await getCollections(baseUrl);

  const refetchCollections = async () => {
    mediaCollections = await getCollections(baseUrl);
    setProps({ mediaCollections });
  };

  const findCollection = (collectionId: string) => {
    const collectionIdx = mediaCollections.findIndex(
      (_collection: CollectionAndStatus) =>
        collectionId === _collection.mediaCollection?.id,
    );
    if (collectionIdx > -1) {
      return mediaCollections[collectionIdx];
    }
  };

  const updateCollectionState = (collectionId: string, status: Status) => {
    const _collection = findCollection(collectionId);
    if (_collection) {
      _collection.memberStatus = status;
      const membersCount = _collection.mediaCollection.membersCount;
      _collection.mediaCollection.membersCount = isNotListMember(status)
        ? membersCount - 1
        : membersCount + 1;
      setProps({ mediaCollections });
    }
  };

  const joinAndUpdateState = async (collectionId: string, name?: string) => {
    await joinCollection(collectionId);
    updateCollectionState(collectionId, Status.MEMBER);
    setProps({
      controllerToast: {
        content: translateWithParams(t, 'COLLECTION_JOIN_TOAST_SUCCESS', {
          listName: name,
        }),
      },
    });
  };

  wixCodeApi.user.onLogin(() => {
    refetchCollections();
  });

  const onJoinCollection = async (collectionId: string) => {
    if (!wixCodeApi.user.currentUser.loggedIn) {
      await appStateManager.login('join list lol page');
      await refetchCollections();
      const _collection = findCollection(collectionId);
      if (isNotListMember(_collection?.memberStatus)) {
        joinAndUpdateState(collectionId, _collection?.mediaCollection.name);
      }
    } else {
      const _collection = findCollection(collectionId);
      await joinAndUpdateState(collectionId, _collection?.mediaCollection.name);
    }
  };

  const onCreateModalOpen = async () => {
    if (!wixCodeApi.user.currentUser.loggedIn) {
      await appStateManager.login('create list');
    }
    setProps({
      actionCallbacks: [{ callbackName: 'openCreateModal' }] as Callbacks,
    });
  };

  const onLeaveCollection = async (collectionId: string) => {
    await leaveCollection(collectionId);
    updateCollectionState(collectionId, Status.NOT_MEMBER);
  };

  const navigator = createNavigator(wixCodeApi);

  const createCollection = async ({
    name,
    privacySettings,
    description,
    origin,
  }: {
    name: string;
    privacySettings?: PrivacySettings;
    description?: string;
    origin: string;
  }) => {
    const { mediaCollectionId } = await createCollectionOnServer({
      name,
      privacySettings,
      description,
      items: [],
    });
    if (mediaCollectionId) {
      const user = {
        role: wixCodeApi.user.currentUser?.role,
        id: wixCodeApi.user.currentUser?.id,
      };
      const collectionParams = JSON.stringify({
        name,
        privacySettings,
        description,
      });
      bi?.report(
        collectionCreated({
          albumID: albumData.settings.id,
          collection_id: mediaCollectionId,
          instance_id: instanceId,
          origin,
          params: collectionParams,
          role: user?.role,
          visitorId: user.id,
        }),
      );
      navigator.collection(mediaCollectionId);
      refetchCollections();
    }
    return mediaCollectionId;
  };

  return {
    pageReady: () => {
      setProps({
        instance,
        mediaCollections,
        instanceId,
        appParams,
        navigator,
        siteName: albumData?.settings.albumName,
        onJoinCollection,
        onLeaveCollection,
        refetchCollections,
        createCollection,
        loggedIn: wixCodeApi.user.currentUser.loggedIn,
        promptLogin: () => wixCodeApi.user.promptLogin({ modal: true }),
        onCreateModalOpen,
        user: {
          role: wixCodeApi.user.currentUser?.role,
          id: wixCodeApi.user.currentUser?.id,
        },
        albumData,
        // @ts-expect-error
        staticMediaUrls: flowAPI.controllerConfig.platformAPIs.topology.media,
      });
    },
  };
};

export default createController;
