import { IHttpClient } from '@wix/yoshi-flow-editor';

import type {
  CreateMediaCollectionResponse,
  CreateMediaCollectionRequest,
  AddItem,
  ListAllItemsBelongToCollectionResponse,
  ListCollectionItemsResponse,
  CollectionItem,
} from '@wix/ambassador-collections-v1-collection/types';

const collectionsApi = (httpClient: IHttpClient) => {
  const getCollection: (
    collectionId: string,
    baseUrl: string,
  ) => Promise<CollectionAndStatus> = async (collectionId, baseUrl) => {
    const { data } = await httpClient.get<CollectionAndStatus>(
      `${baseUrl}/_api/media-collections-service/v1/mediacollections/${collectionId}`,
    );
    return data;
  };

  const getCollectionItemIds: (
    collectionId: string,
    baseUrl: string,
  ) => Promise<string[]> = async (collectionId, baseUrl) => {
    const { data } = await httpClient.get<ListCollectionItemsResponse>(
      `${baseUrl}/_api/media-collections-service/v1/mediacollections/${collectionId}/items`,
    );
    if (data.collectionItems) {
      return data.collectionItems.map(
        (item: CollectionItem) => item.itemId || '',
      );
    }
    return [];
  };

  const getItemIdsInCollections: (
    baseUrl: string,
  ) => Promise<string[]> = async (baseUrl) => {
    const { data } =
      await httpClient.get<ListAllItemsBelongToCollectionResponse>(
        `${baseUrl}/_api/media-collections-service/v1/mediacollections/items`,
      );

    return data.itemIds || [];
  };

  const getCollections = async (baseUrl: string) => {
    const response: CollectionAndStatus[] = [];
    const hasMore = true;
    const offset = 0;

    const { data } = await httpClient.get<{
      collectionsStatus: CollectionAndStatus[];
    }>(
      `${baseUrl}/_api/media-collections-service/v1/mediacollections?offset=${offset}`,
    );

    return data.collectionsStatus;
  };

  const getCollectionMembers: (
    collectionId: string,
    baseUrl: string,
  ) => Promise<{ membersRoles: MemberStatus[] }> = async (
    collectionId,
    baseUrl,
  ) => {
    const { data } = await httpClient.get<{ membersRoles: MemberStatus[] }>(
      `${baseUrl}/_api/media-collections-service/v1/mediacollections/${collectionId}/members`,
    );
    return data;
  };

  const createCollectionOnServer = async ({
    description,
    items,
    name,
    privacySettings,
  }: CreateMediaCollectionRequest) => {
    const { data } = await httpClient.post<CreateMediaCollectionResponse>(
      `/_api/media-collections-service/v1/mediacollections`,
      {
        description,
        items,
        name,
        privacySettings,
      },
    );
    return data;
  };

  const deleteCollection = async (collectionId: string) => {
    const { data } = await httpClient.delete(
      `/_api/media-collections-service/v1/mediacollections/${collectionId}`,
    );
    return data;
  };

  const updateCollectionOnServer = async ({
    collection,
    fieldMask,
  }: {
    collection: MediaCollection;
    fieldMask: string[];
  }) => {
    const { data } = await httpClient.put(
      `/_api/media-collections-service/v1/mediacollections/${collection.id}`,
      {
        field_mask: {
          paths: fieldMask,
        },
        media_collection: collection,
      },
    );
    return data;
  };

  const getItemCollections = async (itemId: string, galleryId: string) => {
    const result = await httpClient.get<{
      itemInCollections: ItemMediaCollection[];
    }>(
      `/_api/media-collections-service/v1/mediacollections/items/${itemId}/collections?galleryId=${galleryId}`,
    );

    return result.data;
  };

  const joinCollection: (collectionId: string) => Promise<{}> = async (
    collectionId: string,
  ) => {
    const { data } = await httpClient.post<{}>(
      `/_api/media-collections-service/v1/mediacollections/${collectionId}/members`,
      {},
    );
    return data;
  };

  const leaveCollection: (collectionId: string) => Promise<{}> = async (
    collectionId: string,
  ) => {
    const { data } = await httpClient.delete<{}>(
      `/_api/media-collections-service/v1/mediacollections/${collectionId}/members`,
    );
    return data;
  };

  const removeFromCollection: (
    collectionId: string,
    memberId: string,
  ) => Promise<{}> = async (collectionId, memberId) => {
    const { data } = await httpClient.delete<{}>(
      `/_api/media-collections-service/v1/mediacollections/${collectionId}/members/${memberId}`,
    );
    return data;
  };

  const addItems = async (collectionId: string, items: AddItem[]) => {
    const { data } = await httpClient.post(
      `/_api/media-collections-service/v1/mediacollections/${collectionId}/items`,
      { items },
    );
    return data;
  };

  const removeItems = async (collectionId: string, itemsIds: string[]) => {
    const { data } = await httpClient.delete(
      `/_api/media-collections-service/v1/mediacollections/${collectionId}/items`,
      {
        data: { itemsIds },
      },
    );
    return data;
  };

  const getItemsZipUrl = async (
    collectionId: string,
    metaSiteId: string,
    instanceId: string,
    itemIds?: string[],
  ) => {
    const result = await httpClient.get(
      `/albums-node-server/collections/${collectionId}/downloadZipAll?instanceId=${instanceId}&metaSiteId=${metaSiteId}`,
    );
    return result.data;
  };

  return {
    getCollection,
    getCollectionItemIds,
    getItemIdsInCollections,
    getCollections,
    getCollectionMembers,
    createCollectionOnServer,
    deleteCollection,
    updateCollectionOnServer,
    getItemCollections,
    joinCollection,
    leaveCollection,
    removeFromCollection,
    addItems,
    removeItems,
    getItemsZipUrl,
  };
};

export default collectionsApi;
