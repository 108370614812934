import { getRootPath } from '../common/utils';

export const createNavigator: (wixCodeApi: any) => INavigator = (
  wixCodeApi,
) => {
  const rootPath = getRootPath(wixCodeApi.location.baseUrl);
  const collectionsListPath = rootPath + `/lists`;
  const collectionPath = rootPath + '/list/';
  return {
    home: () => {
      wixCodeApi.location.to(rootPath);
    },
    collectionsList: () => {
      wixCodeApi.location.to(collectionsListPath);
    },
    collection: (collectionId: string) => {
      wixCodeApi.location.to('/list/' + collectionId);
    },
    sets: () => {
      wixCodeApi.location.to('/sets');
    },
    set: (setId: string) => {
      wixCodeApi.location.to('/set/' + setId);
    },
    rootPath,
    collectionsListPath,
    collectionPath,
  };
};
